<template>
  <c-box
    w="100%"
    max-width="1270px"
    mx="auto"
    bg="#FFF"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['16px', '30px']"
    :min-height="['unset', '74vh']"  
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        padding="20px 90px"
        :paths="[
          { label: 'Beranda', href: { name: 'nutrisionist.managementClient' } },
          { label: 'Manajemen Klien', href: { name: 'nutrisionist.managementClient' } },
          { label: 'Detail Klien', isCurrent: true },
        ]"
      />
    </Portal>

    <ManagementClientDetailAlt 
      :role="authRole"
    />
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import ManagementClientDetailAlt from '@/components/managements/clients/detail-alt'

export default {
  name: 'DetailClient',
  components: {
    BreadcrumbPath,
    ManagementClientDetailAlt,
  },
}
</script>
