var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1270px",
      "mx": "auto",
      "bg": "#FFF",
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['16px', '30px'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "padding": "20px 90px",
      "paths": [{
        label: 'Beranda',
        href: {
          name: 'nutrisionist.managementClient'
        }
      }, {
        label: 'Manajemen Klien',
        href: {
          name: 'nutrisionist.managementClient'
        }
      }, {
        label: 'Detail Klien',
        isCurrent: true
      }]
    }
  })], 1), _c('ManagementClientDetailAlt', {
    attrs: {
      "role": _vm.authRole
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }